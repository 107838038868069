<template>
  <div>
    <my-nav-bar
      title="赠送商品"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list v-model="loading" class="list" :finished="finished" @load="getList">

        <div v-for="(goods, index) in list" :key="index" class="goods-container">
          <div class="goods">
            <div class="goods-image">
              <van-image :src="goods.image" />
            </div>
            <div class="goods-info">
              <div class="goods-name">{{ goods.name }}</div>
              <div class="goods-size">规格：1 * {{ goods.size }}</div>
            </div>
          </div>
          <van-cell title="查看详情" is-link center @click="toDetailView(goods.goods_id)" />
        </div>

      </van-list>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getGift } from '@/api/goods-report'
export default {
  name: 'GoodsReportGift',
  components: { myNavBar },
  data() {
    return {
      showEmpty: false,
      loading: false,
      finished: false,
      list: [],
      listQuery: {
        page: 0,
        store_id: this.$route.query.store_id
      }
    }
  },
  created() {
    // 缓存当前页面
    this.$store.commit('setKeepAlive', this.$options.name)
  },
  beforeRouteLeave(to, from, next) {
    // 清除缓存
    if (to.path !== '/goods-report-gift-detail') {
      this.$store.commit('rmKeepAlive', this.$options.name)
    }
    next()
  },
  methods: {
    getList() {
      this.listQuery.page++
      getGift(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.showEmpty = this.list.length === 0
        this.loading = false
        this.finished = res.data.list.length < 10
      })
    },
    toDetailView(goodsId) {
      this.$router.push({
        path: '/goods-report-gift-detail',
        query: {
          store_id: this.listQuery.store_id,
          goods_id: goodsId
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .list {
    padding: 10px;
    padding-bottom: 0;
  }
  .goods-container {
    margin-bottom: 10px;
  }
  .goods {
    position: relative;
    display: flex;
    background-color: #fff;
    padding: 10px;
  }
  .goods::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 0.42667rem;
    bottom: 0;
    left: 0.42667rem;
    border-bottom: 0.02667rem solid #ebedf0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5);
  }
  .goods-info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 10px;
  }
  .goods-image {
    width: 100px;
    height: 100px;
  }
  .goods-image .van-image {
    width: 100px;
    height: 100px;
  }
  .goods-name {
    font-size: 15px;
  }
  .goods-size {
    color: #989898;
    font-size: 13px;
  }
  .goods-quantity {
    color: #989898;
    font-size: 13px;
  }
</style>
